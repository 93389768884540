
const nameMap = new Map([
  ['userwater', '用水信息'],
  ['meter', '水表信息']
])

export const formFieldList = [
  {
    name: 'usewaterCode',
    label: '用水户号',
    form: { tag: 'text' },
    group: 'userwater'
  },
  {
    name: 'customerCode',
    label: '用户编号',
    form: { tag: 'text' },
    group: 'userwater'
  },
  {
    name: 'usewaterName',
    label: '用水户名',
    form: { tag: 'text' },
    group: 'userwater'
  },
  {
    name: 'usenatureId',
    label: '用水性质',
    relation: 'usenature',
    form: { tag: 'text' },
    render: { type: 'select' },
    group: 'userwater'
  },
  {
    name: 'billCycle',
    label: '缴费周期',
    relation: 'billCycle',
    form: { tag: 'text' },
    render: { type: 'select' },
    group: 'userwater'
  },
  {
    name: 'lastBillTime',
    label: '最后出账月份',
    form: { tag: 'text' },
    render: { type: 'date', timeType: 'YYYY-MM' },
    group: 'userwater'
  },
  {
    name: 'usewaterAddress',
    label: '用水地址',
    form: {
      tag: 'text',
      colSpan: 3
    },
    group: 'userwater'
  },
  {
    name: 'meterNo',
    label: '水表编号',
    form: { tag: 'text' },
    group: 'meter'
  },
  {
    name: 'meterType',
    label: '水表类型',
    relation: 'meterType',
    form: { tag: 'text' },
    render: { type: 'select' },
    group: 'meter'
  },
  {
    name: 'meterOutNo',
    label: '水表外码',
    form: { tag: 'text' },
    group: 'meter'
  },
  {
    name: 'diameter',
    label: '口径',
    relation: 'diameter',
    form: { tag: 'text' },
    render: { type: 'select' },
    group: 'meter'
  },
  {
    name: 'installNum',
    label: '装表行码',
    form: { tag: 'text' },
    render: { type: 'padString', suffix: 'm³' },
    group: 'meter'
  },
  {
    name: 'maxnumber',
    label: '量程',
    render: { type: 'padString', suffix: 'm³' },
    form: { tag: 'text' },
    group: 'meter'
  },
  {
    name: 'installDate',
    label: '装表日期',
    render: { type: 'date' },
    form: { tag: 'text' },
    group: 'meter'
  },
  {
    name: 'factory',
    label: '厂家',
    form: { tag: 'text' },
    group: 'meter'
  },
  {
    name: 'meterSealno',
    label: '铅封号',
    form: { tag: 'text' },
    group: 'meter'
  },
  {
    name: 'meterSelfno',
    label: '出厂编码',
    form: { tag: 'text' },
    group: 'meter'
  },
  {
    name: 'remark',
    label: '备注',
    form: { tag: 'text', colSpan: 2 },
    group: 'meter'
  },
  {
    name: 'meterAtts',
    label: '水表附件',
    form: { tag: 'text', colSpan: 3 },
    render: { type: 'file', showType: 'image' },
    group: 'meter'
  }
]

const group = formFieldList.reduce((res, field) => {
  if (!res[field.group]) res[field.group] = []
  res[field.group].push(field.name)
  return res
}, {})

export const groupConfig = Object.keys(group).reduce((res, item) => {
  res.title.push(nameMap.get(item))
  res.split.push(group[item])
  return res
}, { title: [], split: [] })
