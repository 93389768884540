<template lang="pug">
.full-box.userwater-main(v-loading="loading")
  .button-box
    ButtonGroup(:buttonList="buttonList")
    span.tips 共 {{ curRecordIndex + 1 }}/{{ records.sortIds.length }} 条
  .bgc-block-cross
  .content-box
    .customer-box
      label.blue-header
        span 抄表信息
      .info-box
        DataInfo(
          v-if="showForm"
          :data="recordData"
          :fields="recordFormFields")
    .bgc-block
    .userwater-form-box
      DataformGroup(
        v-if="showForm"
        ref="form"
        v-model="formData"
        groupType="row"
        dot
        :colspanNum="3"
        :groupSplit="groupSplit"
        :groupTitle="groupTitle"
        :formFields="formFields")
</template>

<script>
import { mapActions } from 'vuex'
import { groupConfig, formFieldList } from '../config'
import { composePromise, renderRelationColumns } from '@/utils/common.js'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'RecordCheck',
  watch: {
    '$route.query.recordId': {
      immediate: true,
      handler (id) {
        if (id && Number(id) !== this.recordId) {
          this.recordId = Number(id)
          this.getData()
        }
      }
    }
  },
  computed: {
    curRecordIndex () {
      return this.records.sortIds.indexOf(this.recordId)
    },
    preRecordIndex () {
      // 上一个
      const curIndex = this.curRecordIndex
      return curIndex !== -1 ? curIndex - 1 : -1
    },
    nextRecordIndex () {
      // 下一个
      const curIndex = this.curRecordIndex
      return curIndex !== -1 && curIndex + 1 < this.records.sortIds.length ? curIndex + 1 : -1
    }
  },
  data () {
    return {
      recordId: null,
      loading: false,
      recordData: {},
      recordFormFields: [
        {
          name: 'usewaterCode',
          label: '用水户',
          render: (value, field, data) => {
            const { customerName, usewaterCode } = data
            return `${customerName}(${usewaterCode})`
          }
        },
        { name: 'usewaterAddress', label: '用水地址' },
        {
          name: 'meterOutNo',
          label: '水表外码'
        },
        {
          name: 'maxnumber',
          label: '量程'
        },
        {
          name: 'totalSub',
          label: '总分表',
          relation: 'totalSubType',
          options: [],
          totalUsewaterIdOptions: [],
          render: (value, field, data) => {
            if (!value) {
              return '非总分表'
            } else {
              const result = []
              const type = field.options.find(item => item.value === data.totalSubType) || {}
              result.push(type.label || '-')
              if (data.totalSubType === 'SUB') {
                const item = field.totalUsewaterIdOptions.find(item => item.value === data.totalUsewaterId) || {}
                result.push(item.label || '-')
              }
              return result.join('|')
            }
          }
        },
        { name: 'lastReadingNum', label: '上次抄表行码', render: { type: 'padString', suffix: 'm³' } },
        { name: 'thisReadingNum', label: '本次抄表行码', render: { type: 'padString', suffix: 'm³' } },
        { name: 'quantity', label: '水量', render: { type: 'padString', suffix: 'm³' } },
        { name: 'estimate', label: '估抄', render: { type: 'yesOrNo' } },
        {
          name: 'meterCondition',
          label: '表况',
          relation: 'meterStatus',
          render: { type: 'select' }
        },
        { name: 'readingStaffName', label: '抄表员' },
        { name: 'readingTime', label: '抄表时间', render: { type: 'date', timeType: 'YYYY-MM-DD HH:mm' } },
        {
          name: 'readingAttchs',
          label: '附件',
          render: { type: 'file', showType: 'image', colSpan: 3 }
        }
      ],
      showForm: false,
      groupSplit: groupConfig.split,
      groupTitle: groupConfig.title,
      formFields: formFieldList,
      buttonList: [{
        name: 'closepage',
        label: '关闭',
        func: this.handleClose
      }, {
        name: 'pageUp',
        label: '上一条',
        func: this.handlePageUp
      }, {
        name: 'submit',
        label: '开账',
        type: 'primary',
        validate: true,
        func: () => {
          const formData = this.recordData
          return new Promise((resolve, reject) => {
            this.$post({
              url: '/meterReading/auditBilling',
              data: {
                ids: [formData.id]
              }
            })
              .then(res => {
                if (!res) {
                  reject(res)
                  return
                }
                // 开账成功，更新当前状态，进入下一条
                if (this.records.sortIds.length === 1) {
                  this.$message.success('已全部开账成功')
                  this.handleClose()
                } else {
                  const curRecordId = this.recordId
                  const curRecordIdIndex = this.curRecordIndex
                  this.handlePageDown()
                  delete this.records.status[curRecordId]
                  this.records.sortIds.splice(curRecordIdIndex, 1)
                  this.$message.success('开账成功')
                  resolve()
                }
              })
              .catch(reject)
          })
        }
      }, {
        name: 'pageDown',
        label: '下一条',
        func: this.handlePageDown
      }],
      records: {
        sortIds: [],
        status: {}
      }
    }
  },
  methods: {
    ...mapActions('tagsView', ['delView']),
    ...mapActions('relation', ['getRelations']),
    getRecordById ({ recordId }) {
      return new Promise((resolve, reject) => {
        this.$get({
          url: `/meterReading/get/${recordId}`
        })
          .then(res => {
            if (!res) {
              reject(res)
            } else {
              resolve({ record: res.data })
            }
          })
          .catch(reject)
      })
    },
    getUserwaterById ({ record }) {
      return new Promise((resolve, reject) => {
        this.$get({
          url: `/usewater/get/${record.usewaterId}`
        })
          .then(res => {
            if (!res) {
              reject(res)
            } else {
              const usewater = {
                ...res.data,
                ...res.data.meter
              }
              delete usewater.meter
              resolve({ usewater, record })
            }
          })
          .catch(reject)
      })
    },
    renderData ({ record, usewater }) {
      this.getTotalUsewaterIdOptions(record.orgId)
      this.recordData = record
      this.formData = usewater
      this.showForm = true
      this.loading = false
    },
    getData () {
      const step = [
        this.renderData,
        this.getUserwaterById,
        this.getRecordById
      ]
      this.loading = true
      composePromise(step)({ recordId: this.recordId })
        .catch(e => {
          console.error(e)
          this.loading = false
        })
    },
    renderRelation () {
      this.getRelations(['readingStatus', 'meterCondition', 'meterStatus', 'usenature', 'billCycle', 'meterType', 'totalSubType', 'diameter'])
        .then(res => {
          renderRelationColumns(res, [this.recordFormFields, this.formFields])
          this.$nextTick(() => { this.showTable = true })
        })
    },
    getFullRecord () {
      this.$get({
        url: 'meterReading/readingStatusBill',
        params: {
          readingStatus: 'READED'
        }
      })
        .then(res => {
          if (!res) return
          this.records = {
            sortIds: res.data.sort,
            status: res.data.data
          }
        })
    },
    /**
     * 上下条切换
     * @param {*} key preRecordIndex | nextRecordIndex
     * @param {*} initIndex 默认值
     */
    handlePageChange (key, initIndex) {
      if (this.records.sortIds.length === 1) {
        this.$message.warning('已经是最后一条了')
      } else {
        const id = this.records.sortIds[this[key] === -1 ? initIndex : this[key]]
        this.$router.push({
          name: 'recordCheck',
          query: {
            recordId: id + ''
          }
        })
        // this.recordId = id
      }
    },
    handlePageUp () {
      this.handlePageChange('preRecordIndex', this.records.sortIds.length - 1)
    },
    handlePageDown () {
      this.handlePageChange('nextRecordIndex', 0)
    },
    handleClose () {
      const view = cloneDeep(this.$route)
      this.$router.push({
        path: '/meterRead/meterReadingCheck'
      })
      window.globalEvent.emit('delView', view)
      this.$nextTick(() => {
        window.globalEvent.emit('renderRecordCheckTable')
      })
      // this.delView({ path })
    },
    // 获取总表数据
    getTotalUsewaterIdOptions (orgId) {
      return new Promise((resolve, reject) => {
        this.$get({ url: `/usewater/getCustomerTotalUsewater?orgId=${orgId}` })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            const index = this.recordFormFields.findIndex(item => item.name === 'totalSub')
            this.recordFormFields[index].totalUsewaterIdOptions = res.data ? res.data.map(item => {
              return { value: item.id, label: `${item.customerName}(${item.customerCode})` }
            }) : []
            resolve()
          })
          .catch(reject)
      })
    }
  },
  created () {
    this.renderRelation()
    // this.getData()
    this.getFullRecord()
  }
}
</script>

<style lang="sass" scoped>
.userwater-main
  .button-box
    display: flex
    align-items: center
    padding: 8px
    .tips
      margin-left: 8px
      line-height: 28px
      font-size: 14px
      color: #606266
  .content-box
    width: 100%
    height: calc(100% - 64px)
    display: flex
    flex-direction: row
    .customer-box
      display: flex
      flex-flow: column
      width: 260px
      padding: 8px
      background-color: #fff
      .info-box
        flex: 1
        overflow-y: auto
        padding-top: 8px
    .userwater-form-box
      position: relative
      flex: 1
      background-color: #fff
      overflow-y: auto
.bgc-block-cross
  width: 100%
  height: 8px
  background-color: #eceff0
.bgc-block
  width: 8px
  height: 100%
  background-color: #eceff0
.customer-table-box
  height: 500px
.ws-empty
  text-align: center
  font-size: 14px
  color: #595959
.ml8
  margin-left: 8px
</style>
